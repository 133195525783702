import $ from 'jquery';

let formatFileName = (name) => {
  return name.split('\\').pop();
};

let setFileName = ($fileInput) => {
  $fileInput.next('.custom-file-label')
    .addClass('selected')
    .html(formatFileName($fileInput.val()));
};

$('.custom-file-input').on('change', function() {
  setFileName($(this));
});

$(() => {
  $('.custom-file-input').each(function() {
    let $fileInput = $(this);
    let name = $fileInput.val();

    if (name !== '') {
      setFileName($fileInput);
    }
  });
});
